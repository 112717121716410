import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { DAYMODE, FILTEREVENTS, GlobalViewConstant, HOURDURATION, WORKFLOWEVENT } from '../shared/constants/global-view-constant';
import { EventStatusItems, WorkFlow, WorkFlowItems, GlobalCalendar } from '../shared/models/global-view';
import { GlobalEventComponent } from './global-event/global-event.component';
import { GlobalChartsComponent } from './global-charts/global-charts.component';
import { GlobalViewService } from '../shared/services/global-view.service';
import { Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { EXCLUDED } from '../shared/constants/camera-profile-constant';
import { MatDialog } from '@angular/material/dialog';
import { CustomCalendarComponent } from '../camera-profile/custom-calendar/custom-calendar.component';
import moment from 'moment';
import { DashboardService } from '../shared/services/dashboard.service';
import { countryList } from '../shared/models/dashabordEventDetails';

noData(Highcharts);
@Component({
  selector: 'app-global-view',
  templateUrl: './global-view.component.html',
  styleUrls: ['./global-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalViewComponent implements OnInit, OnDestroy {
  @ViewChild(GlobalEventComponent) globalEvent: GlobalEventComponent;
  @ViewChild(GlobalChartsComponent) globalChart: GlobalChartsComponent;
  public workFlowItems = GlobalViewConstant.workFlowItems;
  public eventStatusItems = GlobalViewConstant.EventStatusItems;
  public customOption = GlobalViewConstant.customOption;
  public dateRange = GlobalViewConstant.dateRange;
  public dateRangeCalendar: string | null;
  public selectedDateRange: string | null = DAYMODE.MONTH;
  public isCustomCalendarDisabled = false;
  public startDate: Date;
  public endDate: Date;
  public selectedOption: string | null;
  public siteListItem: EventStatusItems[];
  public equipmentListItem: EventStatusItems[];
  public countryList: EventStatusItems[];
  public selectedWorkFlow: string[];
  public selectedWorkFlowId: string[] = [WORKFLOWEVENT.ALL];
  public selectedEventStatus: string[] = [WORKFLOWEVENT.ALL];
  public calendarPlaceholder = 'Select range...';
  public errorMessage = '';
  public maxValue = new Date();
  public selectedRiskStatus: string[] = [WORKFLOWEVENT.ALL];
  public selectedSite: string[] = [WORKFLOWEVENT.ALL];
  public selectedCountry: string[] = [WORKFLOWEVENT.ALL];
  public filterValueChange: boolean;
  public currentFilterSelection: { [key: string]: string[] };
  public previousFilterSelection: { [key: string]: string[] };

  public riskDataList: EventStatusItems[];
  private destroyed = new Subject();
  private SECONDS_IN_HOUR = HOURDURATION.SECONDS_IN_HOUR;
  private HOUR = HOURDURATION.HOUR;
  private DAY = HOURDURATION.DAY;
  private WEEK = HOURDURATION.WEEK;
  private MONTH = HOURDURATION.MONTH;
  private TWODAY = HOURDURATION.TWODAY;
  private YEAR = HOURDURATION.YEAR;
  private prevSelection: string | null = DAYMODE.MONTH;
  private init: boolean;
  private dateInit: boolean;

  constructor(
    private globalViewService: GlobalViewService,
    private messageService: MessageService,
    private cameraProfileService: CameraProfileService,
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.init = true;
    this.filterValueChange = false;
    this.dateInit = true;
    this.globalViewService
      .getWorkFlowId()
      .pipe(
        catchError(() => {
          this.resetLoader();
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.globalWorkFlowAPIError,
            closable: true,
            sticky: true,
          });

          return of<WorkFlow[]>({} as WorkFlow[]);
        }),
        tap((data: WorkFlow[]) => {
          data.map(item => {
            this.workFlowItems.map(workflow => {
              if (workflow.name.toLowerCase().split(' ').join('').includes(item.name.toLowerCase().split(' ').join(''))) {
                workflow.value = item.id;
              }
            });
          });
          this.globalEvent.setFirstInitialization(this.startDate, this.endDate, this.createFilterArray());
          this.globalChart.setFirstInitialization(this.startDate, this.endDate, this.createFilterArray());
        }),
        switchMap(() =>
          this.dashboardService.getCountryData().pipe(
            tap((countryData: countryList) => {
              this.checkAdmin(countryData);
            }),
            catchError(() => {
              this.resetLoader();
              this.messageService.add({
                severity: SlbSeverity.Error,
                summary: GlobalViewConstant.GLOBALCOUNTRYERROR,
                closable: true,
                sticky: true,
              });

              return of<countryList[]>({} as countryList[]);
            }),
            takeUntil(this.destroyed)
          )
        ),
        switchMap(() =>
          this.globalViewService.getRiskList().pipe(
            tap((riskData: string[]) => {
              this.setRiskDataList(riskData);
            }),
            catchError(() => {
              this.resetLoader();
              this.messageService.add({
                severity: SlbSeverity.Error,
                summary: GlobalViewConstant.GLOBALRISKERROR,
                closable: true,
                sticky: true,
              });

              return of<string[]>({} as string[]);
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onCustom(event: string): void {
    this.isCustomCalendarDisabled = this.selectedOption === DAYMODE.CUSTOM ? false : true;
    this.selectedOption = event;
    if (this.selectedOption === DAYMODE.CUSTOM) {
      this.customClick();
    }
    if (event) {
      this.selectedDateRange = null;
    }
  }

  public onSwitchDays(event: string): void {
    if (!this.dateInit && !this.init) {
      this.filterValueChange = true;
    } else {
      this.dateInit = false;
    }
    this.selectedDateRange = event;
    this.filterValueChange = this.selectedDateRange === this.prevSelection ? false : true;
    this.isCustomCalendarDisabled = this.selectedOption === DAYMODE.CUSTOM ? false : true;
    if (event) {
      this.selectedOption = null;
      this.generateDate(event);
    }
  }

  public filter(name: string, checked: boolean, workItem: EventStatusItems[], filterItem: string): void {
    this.filterValueChange = true;
    if (name?.toLowerCase() === WORKFLOWEVENT.ALL && checked) {
      workItem.forEach(item => (item.checked = true));
      this.isAllCheckBoxSelected(workItem, filterItem);
    } else if (name?.toLowerCase() === WORKFLOWEVENT.ALL && !checked) {
      workItem.forEach(item => (item.checked = false));
      this.isAllCheckBxSelectforAllUncheck(workItem);
      this.getSelectedStatus(workItem, filterItem);
    } else if (name !== WORKFLOWEVENT.ALLLABEL) {
      workItem[0].checked = false;
      this.isAllCheckBoxSelected(workItem, filterItem);
    }

    this.currentFilterSelection = this.createFilterArray();
    if (this.previousFilterSelection && JSON.stringify(this.currentFilterSelection) !== JSON.stringify(this.previousFilterSelection)) {
      this.filterValueChange = true;
    } else {
      this.filterValueChange = false;
    }

    if (filterItem === 'country') {
      this.getSiteData(this.selectedCountry);
    }
  }

  public onClear(): void {
    if (!this.init) {
      this.dateInit = true;
      this.filterValueChange = false;
      this.prevSelection = DAYMODE.MONTH;
    }
    this.initData();
    this.cameraProfileService.getEventData(false);
  }

  public apply(): void {
    if (!this.selectedOption) {
      this.globalChart.setChartTimeline(this.selectedDateRange);
    } else {
      this.globalChart.setChartTimeline(DAYMODE.CUSTOM, this.startDate, this.endDate);
    }
    this.globalChart.switchDays(this.startDate, this.endDate);
    this.globalChart.workFlowEventStatusChange(this.createFilterArray());
    this.globalEvent.switchDays(this.startDate, this.endDate);
    this.globalEvent.workFlowEventStatusChange(this.createFilterArray());
    this.cameraProfileService.getEventData(false);
    this.prevSelection = this.selectedDateRange;
    this.previousFilterSelection = this.createFilterArray();
    this.filterValueChange = false;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private getSiteData(countries: string[]): void {
    if (countries.length) {
      this.globalViewService
        .getSiteList(countries)
        .pipe(
          tap((siteList: WorkFlow[]) => {
            this.siteListItem = this.createFilterData(siteList);
            if (this.init) {
              this.onClear();
              this.init = false;
            }
          }),
          catchError(() => {
            this.resetLoader();
            this.messageService.add({
              severity: SlbSeverity.Error,
              summary: GlobalViewConstant.GLOBALSITEERROR,
              closable: true,
              sticky: true,
            });

            return of<WorkFlow[]>({} as WorkFlow[]);
          })
        )
        .subscribe();
    }
  }

  private generateDate(dayMode: string): void {
    switch (dayMode) {
      case DAYMODE.HOUR:
        this.getDate(this.HOUR);
        break;
      case DAYMODE.DAY:
        this.getDate(this.DAY);
        break;
      case DAYMODE.TWODAY:
        this.getDate(this.TWODAY);
        break;
      case DAYMODE.WEEK:
        this.getDate(this.WEEK);
        break;
      case DAYMODE.MONTH:
        this.getDate(this.MONTH);
        break;
      case DAYMODE.YEAR:
        this.getDate(this.YEAR);
        break;
    }
  }

  private getDate(hour: number): void {
    this.endDate = new Date();
    this.startDate = new Date();
    if (hour !== 0) {
      if (hour === this.TWODAY) {
        this.startDate.setDate(this.startDate.getDate() - 1);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.WEEK) {
        this.startDate.setDate(this.startDate.getDate() - 6);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.MONTH) {
        this.startDate.setDate(this.startDate.getDate() - 29);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.YEAR) {
        this.startDate.setDate(this.startDate.getDate() - 364);
        this.startDate.setHours(0, 0, 0, 0);
      } else {
        this.startDate = new Date(new Date().getTime() - hour * this.SECONDS_IN_HOUR);
      }
    }
  }

  private customClick(): void {
    const dialogRef = this.dialog.open(CustomCalendarComponent, {
      width: '550px',
      data: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type === 'save') {
        this.startDate = result.dates.startDate;
        this.endDate = result.dates.endDate;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.onDateSelected(result.dates);
      } else {
        this.dateInit = true;
        this.filterValueChange = false;
        this.selectedDateRange = this.prevSelection;
      }
    });
  }

  private onDateSelected(event: GlobalCalendar): void {
    this.dateRangeCalendar =
      moment(event.startDate).format('MM/DD/YYYY, h:mm:ss a') + ' - ' + moment(event.endDate).format('MM/DD/YYYY, h:mm:ss a');
    if (event.startDate && event.endDate) {
      this.errorMessage = '';
      this.startDate = event.startDate;
      this.endDate = event.endDate;
    }
    this.filterValueChange = true;
  }

  private isAllCheckBoxSelected(workItem: EventStatusItems[], filterItem: string): void {
    const selectedCount = workItem?.filter(item => item.checked)?.length;
    if (selectedCount === workItem?.length - 1) {
      workItem[0].checked = true;
    } else if (selectedCount === 0) {
      this.isAllCheckBxSelectforAllUncheck(workItem);
    }

    this.getSelectedStatus(workItem, filterItem);
  }

  private getSelectedStatus(workItem: EventStatusItems[], filterItem: string): void {
    if (filterItem === FILTEREVENTS.WORKFLOW) {
      this.selectedWorkFlowId = this.filterSelectedData(workItem, this.selectedWorkFlowId);
    } else if (filterItem === FILTEREVENTS.RISK) {
      this.selectedRiskStatus = this.filterSelectedData(workItem, this.selectedRiskStatus);
    } else if (filterItem === FILTEREVENTS.EVENT) {
      this.selectedEventStatus = this.filterSelectedData(workItem, this.selectedEventStatus);
    } else if (filterItem === FILTEREVENTS.SITE) {
      this.selectedSite = this.filterSelectedData(workItem, this.selectedSite);
    } else if (filterItem === FILTEREVENTS.COUNTRY) {
      this.selectedCountry = this.filterSelectedData(workItem, this.selectedCountry);
    }
  }

  private isAllCheckBxSelectforAllUncheck(workFlowItems: WorkFlowItems[]): void {
    const selectedCount = workFlowItems?.filter(item => item.checked)?.length;
    if (selectedCount === workFlowItems?.length - 1) {
      workFlowItems[0].checked = true;
    }
    if (selectedCount === 0) {
      setTimeout(() => {
        workFlowItems.forEach(item => (item.checked = true));
      }, 100);
    }
  }

  private filterSelectedData(items: EventStatusItems[], filterData: string[]): string[] {
    if (items?.length) {
      filterData = items.filter(wf => wf.checked === true).map(wf => wf.value);
    } else {
      filterData = [];
    }
    const checkAll = filterData?.find((swf: string) => swf?.toLowerCase() === WORKFLOWEVENT.ALL) || !filterData.length ? true : false;
    if (checkAll) {
      filterData = [];
      filterData.push(WORKFLOWEVENT.ALL);
    }

    return filterData;
  }

  private createFilterData(dataList: WorkFlow[]): EventStatusItems[] {
    if (dataList?.length) {
      const filterArr: EventStatusItems[] = [
        {
          name: 'All',
          value: 'All',
          checked: true,
        },
      ];
      dataList.forEach(list =>
        filterArr.push({
          name: list.name,
          value: list.id,
          checked: true,
        })
      );

      return filterArr;
    }

    return [];
  }

  private setCountryData(country: string[]): EventStatusItems[] {
    if (country?.length) {
      const filterArr: EventStatusItems[] = [
        {
          name: 'All',
          value: 'All',
          checked: true,
        },
      ];
      country.forEach(countryName =>
        filterArr.push({
          name: countryName,
          value: countryName,
          checked: true,
        })
      );

      return filterArr;
    }

    return [];
  }

  private createFilterArray(): { [key: string]: string[] } {
    const data: { [key: string]: string[] } = {
      workFlow: this.selectedWorkFlowId,
      reviewCategory: this.selectedEventStatus,
      riskCategories: this.selectedRiskStatus,
      countryName: this.selectedCountry,
      site: this.selectedSite,
    };

    return data;
  }

  private resetLoader(): void {
    this.globalChart.riskLoader = false;
    this.globalChart.alertsLoader = false;
    this.globalChart.summaryLoader = false;
    this.globalEvent.isLoading = false;
  }

  private setRiskDataList(riskData: string[]): void {
    this.riskDataList = [];
    if (riskData?.length) {
      this.riskDataList.push({
        name: WORKFLOWEVENT.ALLLABEL,
        value: WORKFLOWEVENT.ALLLABEL,
        checked: true,
      });
    }
    riskData.forEach((risk: string) =>
      this.riskDataList.push({
        name: risk.match(/[A-Z][a-z]+/g)?.join(' ') ?? '',
        value: risk.charAt(0).toLowerCase() + risk.substring(1),
        checked: true,
      })
    );
  }

  private checkAdmin(countryData: countryList): void {
    this.countryList = this.setCountryData(countryData.country);
    sessionStorage.setItem('isAdmin', countryData.isAdmin.toString());
    this.cameraProfileService.setAdmin(countryData.isAdmin.toString());
    if (!countryData?.isAdmin) {
      this.eventStatusItems = this.eventStatusItems.filter(review => review.name.toLowerCase() !== EXCLUDED);
    }
    this.getSiteData([WORKFLOWEVENT.ALLLABEL]);
  }

  private setAllCheckboxSelected(workItem: EventStatusItems[], filterItem: string): void {
    workItem.forEach(item => (item.checked = true));
    this.getSelectedStatus(workItem, filterItem);
  }

  private initData(): void {
    this.selectedDateRange = DAYMODE.MONTH;
    this.generateDate(this.selectedDateRange);
    this.setAllCheckboxSelected(this.eventStatusItems, 'event');
    this.setAllCheckboxSelected(this.workFlowItems, 'workflow');
    this.setAllCheckboxSelected(this.riskDataList, 'risk');
    this.setAllCheckboxSelected(this.siteListItem, 'site');
    this.setAllCheckboxSelected(this.countryList, 'country');

    this.dateRangeCalendar = null;
    this.globalChart.setChartTimeline(this.selectedDateRange);
    this.globalChart.switchDays(this.startDate, this.endDate);
    this.globalChart.workFlowEventStatusChange(this.createFilterArray());
    this.globalEvent.switchDays(this.startDate, this.endDate);
    this.globalEvent.workFlowEventStatusChange(this.createFilterArray());
    this.previousFilterSelection = this.createFilterArray();
  }
}
