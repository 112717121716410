import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { CommentDetail, EventDetails, RigData, RigValue } from '../../shared/models/eventDetails';
import {
  BPOS,
  BVEL,
  EVENT_PAGINATION_OPTIONS,
  EVENT_TABLE_HEADING,
  NONE,
  RPM,
  SPPA,
  VIDEO,
} from '../../shared/constants/camera-profile-constant';
import { Subject, tap } from 'rxjs';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { DashboardService } from '../../../app/shared/services/dashboard.service';
import { GatewayStatus } from '../../../app/shared/constants/enum';
import { UnitsData } from '../../../app/shared/models/unit';
import { UserSettingsService } from '../../../app/shared/services/user-settings.service';
import { RIGDETAILS } from '../../../app/shared/constants/comment-contant';

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {
  @Input() gridData: EventDetails[];
  @Input() totalNoRecords: number;
  @Input() paginatedData: PageEvent;
  @Input() gatewayId: string;
  @Input() controllerId: string;
  @Input() isLoading: boolean;
  @Output() selectedData: EventEmitter<EventDetails[]> = new EventEmitter<EventDetails[]>();
  @Output() pageData: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public displayedColumns: string[] = EVENT_TABLE_HEADING;
  public dataSource: MatTableDataSource<EventDetails, MatTableDataSourcePaginator>;
  public selection = new SelectionModel<EventDetails>(true, []);
  public pageSizeOptions: number[] = EVENT_PAGINATION_OPTIONS;
  public popoverData: EventDetails;
  public source: string;
  public popOverCameraDetails: EventDetails | null;
  public currentZone = '';
  public hasNext = false;
  public hasPrevious = false;
  public selectedPopOverIndex = -1;
  public isOpen = false;
  public rigDetails: RigData[];
  public bposHeader: string;
  public bvelHeader: string;
  public rpmHeader: string;
  public sppaHeader: string;
  private destroyed = new Subject();
  private unitSelected: UnitsData;
  private rigValue: RigValue;
  private rigData: CommentDetail[];
  private gatewaySessionStatus: string;

  constructor(
    private timeZoneService: TimeZoneService,
    private dashboardService: DashboardService,
    private userSettingService: UserSettingsService
  ) {
    this.currentZone = this.timeZoneService.getTimeZone();
    this.userSettingService.unitSelected$
      .pipe(
        tap((value: string) => {
          this.unitSelected = JSON.parse(value);
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.constructHeader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['gridData'] && changes['gridData'].currentValue !== '') {
      this.gatewaySessionStatus = sessionStorage.getItem('gatewayStatus') ?? '';
      this.selection.clear();
      this.gridData.map((data: EventDetails) => {
        if (data) {
          data.controllerId = this.controllerId;
          data.gatewayId = this.gatewayId;
          data.gatewayStatus = this.gatewaySessionStatus as unknown as GatewayStatus;
          data.isClicked = false;
          if (data?.media?.length > 0) {
            data.isVideo = data.media.filter(med => med.type.toLowerCase() === VIDEO)?.length > 0 ? true : false;
          }
          if (data?.isChecked) {
            this.selection.selected.push(data);
          }
          data.category = !data.category ? NONE : data.category;
          this.rigValue = data?.rigData;
          if (this.rigValue) {
            this.constructRigDetails();
            data.BPOS = this.rigDetails.find(f => f.label === BPOS)?.currentValue;
            data.BVEL = this.rigDetails.find(f => f.label === BVEL)?.currentValue;
            data.RPM = this.rigDetails.find(f => f.label === RPM)?.currentValue;
            data.SPPA = this.rigDetails.find(f => f.label === SPPA)?.currentValue;
          }
        }
      });
      this.popOverCameraDetails = this.selectedPopOverIndex !== -1 ? this.gridData[this.selectedPopOverIndex] : null;
      this.selection.setSelection(...this.selection.selected);
      this.dataSource = new MatTableDataSource(this.gridData);
      this.isAllSelected();
      if (this.isOpen) {
        this.onEventClick(this.selectedPopOverIndex);
      }
    }
    if (this.paginator && this.paginatedData) {
      this.paginator.pageIndex = this.paginatedData.pageIndex;
      this.paginator.pageSize = this.paginatedData.pageSize;
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator && this.paginatedData) {
      this.paginator.pageIndex = this.paginatedData.pageIndex;
      this.paginator.pageSize = this.paginatedData.pageSize;
    }
  }

  ngDoCheck(): void {
    this.dataSource.sort = this.sort;
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();

      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  public checkboxChange(): void {
    this.selectedData.emit(this.selection.selected);
  }

  public onImageClick(eventDetails: EventDetails, index: number): void {
    this.dataSource.data.forEach(data => {
      if (data?.isClicked) {
        data.isClicked = false;
      }
    });
    this.isOpen = !this.isOpen;
    this.selectedPopOverIndex = index;
    const mapData: EventDetails = eventDetails;
    const pageEvent = this.paginatedData
      ? this.paginatedData
      : ({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: 10,
          length: this.totalNoRecords,
        } as PageEvent);
    this.checkNext();
    this.checkPrevious(pageEvent);
    this.popOverCameraDetails = mapData;
  }

  public onEventClick(event: number): void {
    const action = event === this.selectedPopOverIndex - 1 ? 'prev' : 'next';
    this.selectedPopOverIndex = event;
    const pageEvent = this.paginatedData
      ? this.paginatedData
      : ({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: 10,
          length: this.totalNoRecords,
        } as PageEvent);
    this.checkNext();
    this.checkPrevious(pageEvent);
    if (this.gridData[this.selectedPopOverIndex]) {
      const data: EventDetails = this.gridData[this.selectedPopOverIndex];
      this.popOverCameraDetails = data;
    } else if (this.hasNext) {
      pageEvent.pageIndex = action === 'next' ? pageEvent.pageIndex + 1 : pageEvent.pageIndex - 1;
      this.selectedPopOverIndex = action === 'next' ? 0 : pageEvent.pageSize - 1;
      this.pageData.emit(pageEvent);
    }
  }

  public checkNext(): void {
    this.hasNext = this.gridData.length > this.selectedPopOverIndex + 1;
  }

  public checkPrevious(pageEvent: PageEvent): void {
    this.hasPrevious = this.selectedPopOverIndex <= 0 && pageEvent.pageIndex === 0 ? false : true;
  }

  public pageEvent(event: PageEvent): void {
    this.selection.clear();
    this.pageData.emit(event);
  }

  public getLabel(eventDetails: EventDetails | null | undefined): string {
    if (eventDetails) {
      return this.dashboardService.setLabel(eventDetails);
    } else {
      return '';
    }
  }

  public getIcon(eventDetails: EventDetails | null | undefined): string {
    if (eventDetails) {
      return this.dashboardService.getIcon(eventDetails);
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private constructRigDetails(): void {
    this.rigDetails = [];
    this.rigData = RIGDETAILS.RIGDATA_NONBSP;
    if (this.rigData) {
      this.rigData.forEach(data => {
        if (this.rigValue) {
          const currValue = this.rigValue[data.currentValue as keyof RigValue] as number;
          if (typeof currValue !== 'undefined' && currValue !== null) {
            const getSelectedUnit = this.userSettingService.getUnit(data.defaultUnit, this.unitSelected.unitData);
            const finalObj: RigData = {
              currentValue: this.userSettingService.siToChosenUnit(currValue, data.defaultUnit, getSelectedUnit),
              unit: getSelectedUnit,
              label: data.label,
            };
            this.rigDetails.push(finalObj);
          }
        }
      });
    }
  }

  private constructHeader(): void {
    this.rigData = RIGDETAILS.RIGDATA_NONBSP;
    this.rigData.forEach(data => {
      const getSelectedUnit = this.userSettingService.getUnit(data.defaultUnit, this.unitSelected.unitData);
      this.bposHeader = data.label === BPOS ? data.label + getSelectedUnit : this.bposHeader;
      this.bvelHeader = data.label === BVEL ? data.label + getSelectedUnit : this.bvelHeader;
      this.rpmHeader = data.label === RPM ? data.label + getSelectedUnit : this.rpmHeader;
      this.sppaHeader = data.label === SPPA ? data.label + getSelectedUnit : this.sppaHeader;
    });
  }
}
